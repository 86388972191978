
export default {
	props: {
		displayModal: Boolean,
	},

	data() {
		return {
			updates: [
				{
					image: '/img/update-banner.png',
					text: "This December we've been busy! The Reality Stake it's live and now you can earn Reality Coins by staking your Knights and Cats. We're also made a bunch of updates to the Reality Kingdom, including new features, bug fixes and more!",
				},
			],
			glideInstance: null,
			viewedUpdates: true,
		}
	},

	methods: {
		mountGlideInstance() {
			if (this.updates.length < 2) return // Doesn't need to scroll with only one update

			if (typeof Glide === 'undefined' || !this.$refs.updatesModalCarousel) {
				return setTimeout(() => this.mountGlideInstance(), 100) // 100ms
			}

			const glideSettings = {
				perView: 1,
				gap: 24,
				type: 'slider',
			}

			this.glideInstance = new Glide(this.$refs.updatesModalCarousel, glideSettings)

			this.glideInstance.mount()
		},

		async closeUpdatesModal() {
			window.localStorage.setItem('rk-updatesmodal-viewed', 'true')
			this.viewedUpdates = true
		},
	},

	mounted() {
		const viewedUpdatesModal = window.localStorage.getItem('rk-updatesmodal-viewed')
		this.viewedUpdates = viewedUpdatesModal === 'true'
		this.mountGlideInstance()
	},

	head() {
		return {
			script: [
				{
					src: 'https://cdnjs.cloudflare.com/ajax/libs/Glide.js/3.2.0/glide.min.js',
				},
			],
			link: [
				{
					rel: 'stylesheet',
					href: 'https://cdnjs.cloudflare.com/ajax/libs/Glide.js/3.2.0/css/glide.core.min.css',
				},
			],
		}
	},
}
