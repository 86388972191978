export default ({ $config: { isTestNet }, $sentry }, inject) => {
	inject('sentryCaptureException', error => {
		if (!isTestNet) {
			$sentry.setExtra('error', JSON.stringify(error))
			return $sentry.captureException(error)
		}

		console.error(error)
	})

	const errors = {
		default: {
			title: 'Error',
			content: 'Something went wrong, please try again.',
		},
		insufficient: {
			title: 'Insufficient funds',
			content: "You don't have enough funds for this transaction.",
		},
		invalid: {
			title: 'Insufficient funds',
			content: "You don't have enough funds for this transaction.",
		},
		wrongNetwork: {
			title: 'Wrong network',
			content: `You're connected to the wrong network, please change to ${isTestNet ? 'polygonAmoy' : 'polygon'}.`,
		},
		fetch: {
			title: 'Error fetching items',
			content: "it wasn't possible to fetch items from this wallet, please try again.",
		},
		rejected: {
			title: 'Rejected',
			content: 'Rejected by user, please try again.',
		},
		'raffle does not exist': {
			title: 'Raffle does not exist',
			content: 'This raffle does not exist, please try another.',
		},
		'raffle is not active': {
			title: 'Raffle is not active',
			content: 'This raffle is not active, please try another.',
		},
		'not enough tickets left': {
			title: 'Not enough tickets left',
			content: 'The raffle has sold out.',
		},
		'tickets by wallet limit reached': {
			title: 'Tickets by wallet limit reached',
			content: 'You have reached the limit of tickets per wallet.',
		},
		'raffle is not drawn': {
			title: 'Raffle is not drawn',
			content: 'This raffle is not drawn.',
		},
		'funds have already been withdrawn': {
			title: 'Funds have already been withdrawn',
			content: 'You have already withdrawn your funds.',
		},
	}

	inject('errorHandler', error => {
		const alertObj = { show: true, type: 'danger' }
		const chainId = isTestNet ? 80002 : 137

		if (window.ethereum && window.ethereum.networkVersion != chainId) {
			return { ...alertObj, ...errors.wrongNetwork }
		}

		const errorString = error.toString().toLowerCase()

		for (const key in errors) {
			if (errorString.includes(key)) {
				return { ...alertObj, ...errors[key] }
			}
		}

		return { ...alertObj, ...errors.default }
	})
}
