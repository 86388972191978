
export default {
	props: {
		content: String,
		color: {
			type: String,
			default: '--gray-8',
		},
		colorEffect: {
			type: String,
			default: '--gold-3',
		},
	},
	data() {
		return {
			contentWidth: 700,
		}
	},
	mounted() {
		this.contentWidth = this.$refs.contentWrapper.getBoundingClientRect().width
	},
}
