import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default function ({ $config: { google } }) {
	if (!google.gtag) return

	Vue.use(VueGtag, {
		config: { id: google.gtag },
	})
}
