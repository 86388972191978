export default ({ $axios, $Web3, $config }, inject) => {
	/**
	 * Get the connected web3 instance
	 * @todo Check if this method works with different wallets.
	 */
	const getWeb3Instance = () => {
		const currentNetwork = $config.isTestNet ? '80002' : '137'
		const currentRpc = $config.isTestNet
			? 'https://polygon-polygonAmoy.blockpi.network/v1/rpc/public'
			: 'https://polygon-rpc.com/'

		const web3 = new $Web3(
			window.ethereum && window.ethereum.networkVersion === currentNetwork ? window.ethereum : currentRpc
		)

		web3.eth.handleRevert = true

		return web3
	}

	async function executeContractMethod({
		contractMethod,
		transactionData,
		blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon',
		web3,
		gasLimitMultiplier,
	}) {
		const estimatedGas = await contractMethod.estimateGas(transactionData)
		const { maxFeePerGas, maxPriorityFeePerGas } = await $axios
			.get(`/api/transactions/base-fees/${blockchain}`)
			.then(res => res.data)

		return contractMethod.send({
			...transactionData,
			gasLimit: estimatedGas * gasLimitMultiplier,
			maxFeePerGas: web3.utils.toWei(maxFeePerGas, 'gwei'),
			maxPriorityFeePerGas: web3.utils.toWei(maxPriorityFeePerGas, 'gwei'),
		})
	}

	const getERC1155ContractInstance = async ({ contractAddress, web3 = getWeb3Instance() }) => {
		const ERC1155abi = await $axios
			.get('https://cdn.realitykingdom.xyz/json/ERC1155-ABI.json')
			.then(res => res.data)

		return new web3.eth.Contract(ERC1155abi, contractAddress)
	}

	const getERC721ContractInstance = async ({ contractAddress, web3 = getWeb3Instance() }) => {
		const ERC721abi = await $axios.get('https://cdn.realitykingdom.xyz/json/ERC721-ABI.json').then(res => res.data)

		return new web3.eth.Contract(ERC721abi, contractAddress)
	}

	const groupNftsByContract = nfts => {
		const groupedNfts = {}

		nfts.forEach(nft => {
			if (!groupedNfts[nft.contractAddress]) {
				groupedNfts[nft.contractAddress] = []
			}

			groupedNfts[nft.contractAddress].push(nft)
		})

		return groupedNfts
	}

	/**
	 * Workaround for the issue where sometimes the block has not being fully synced.
	 */
	const getSafestBlock = async web3 => {
		const latestBlockOffset = 25

		return (await web3.eth.getBlockNumber()) - latestBlockOffset
	}

	inject('getContracts', async function (blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon') {
		const contractResponse = await $axios.get(`/api/files/contracts/${blockchain}`).then(res => res.data)
		const web3 = getWeb3Instance()

		return {
			...contractResponse,
			scrollContract: new web3.eth.Contract(contractResponse.RKScrollAbi, contractResponse.silverScrollAddress),
			realitySwapContract: new web3.eth.Contract(contractResponse.swapAbi, contractResponse.swapAddress),
			raffleContract: new web3.eth.Contract(contractResponse.raffleAbi, contractResponse.raffleAddress),
			raffleV1Address: new web3.eth.Contract(contractResponse.raffleAbi, contractResponse.raffleV1Address),
			coinContract: new web3.eth.Contract(contractResponse.coinAbi, contractResponse.coinAddress),
			web3,
			safestBlock: await getSafestBlock(web3),
		}
	})

	/**
	 * @description In order to prevent bug across different wallets, we calculate our own gasLimit and priority fee, this will make sure that all the transactions are processed on time.
	 */
	inject('executeContractMethod', executeContractMethod)

	/**
	 * @description Get a web3 instance for a ERC1155 smart contract.
	 */
	inject('getERC1155ContractInstance', getERC1155ContractInstance)

	/**
	 * @description Get a web3 instance for a ERC721 smart contract.
	 */
	inject('getERC721ContractInstance', getERC721ContractInstance)

	inject('getContractTransactionTotal', async function ({ contract, senderAddress, amount, web3, safestBlock }) {
		const contractFee = this.convertNumberToBigNumber(
			await contract.methods.calculatedFixedFee().call(
				{
					from: senderAddress,
				},
				safestBlock
			)
		)
		const offeredAmount = this.convertNumberToBigNumber(this.convertNumberToWei(amount))
		const totalAmount = offeredAmount.add(contractFee)
		const balance = await web3.eth.getBalance(senderAddress)
		const userBalance = this.convertNumberToBigNumber(balance)

		if (userBalance.lt(totalAmount)) {
			/**
			 * @todo Display error message.
			 */
			throw new Error('Insufficient balance')
		}

		return totalAmount
	})

	inject('hasEnoughBalance', async function ({ senderAddress, web3, totalAmount }) {
		const balance = await web3.eth.getBalance(senderAddress)
		const userBalance = this.convertNumberToBigNumber(balance)

		//greater then
		return userBalance.gt(totalAmount)
	})

	inject(
		'approveTokensForContract',
		async function ({
			senderAddress,
			amount,
			contractAddress,
			contractInstance,
			blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon',
			web3,
			gasLimitMultiplier,
			safestBlock,
		}) {
			const approvedAmount = await contractInstance.methods
				.allowance(senderAddress, contractAddress)
				.call({}, safestBlock)

			if (approvedAmount < amount.toString()) {
				const contractMethod = await contractInstance.methods.approve(contractAddress, amount)

				await executeContractMethod({
					contractMethod,
					transactionData: { from: senderAddress },
					web3,
					gasLimitMultiplier,
					blockchain,
				})
			}
		}
	)

	inject(
		'approveNftsForContract',
		async function ({
			senderAddress,
			contractAddress,
			nfts,
			web3,
			blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon',
			gasLimitMultiplier,
			safestBlock,
		}) {
			const groupedNfts = groupNftsByContract(nfts)

			for (const nftContractAddress in groupedNfts) {
				const tokenType = groupedNfts[nftContractAddress][0].tokenType
				const nftContract = await (tokenType === 'ERC721'
					? getERC721ContractInstance({ contractAddress: nftContractAddress, web3 })
					: getERC1155ContractInstance({ contractAddress: nftContractAddress, web3 }))

				/**
				 * @todo Do the full verification process of the smart contract being able to move the tokens.
				 */
				const approved = await nftContract.methods
					.isApprovedForAll(senderAddress, contractAddress)
					.call({}, safestBlock)

				if (!approved) {
					const contractMethod = await nftContract.methods.setApprovalForAll(contractAddress, true)

					await executeContractMethod({
						contractMethod,
						transactionData: { from: senderAddress },
						web3,
						gasLimitMultiplier,
						blockchain,
					})
				}
			}
		}
	)
}
