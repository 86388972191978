import { render, staticRenderFns } from "./UpdatesModal.vue?vue&type=template&id=2faabf68&scoped=true&"
import script from "./UpdatesModal.vue?vue&type=script&lang=js&"
export * from "./UpdatesModal.vue?vue&type=script&lang=js&"
import style0 from "./UpdatesModal.vue?vue&type=style&index=0&id=2faabf68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faabf68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/usr/src/app/components/Modal.vue').default})
