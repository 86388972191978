export default ({}, inject) => {
	inject('claimPrizeRaffle', async function (raffle, publicAddress) {
		await this.$switchToPolygonNetwork()

		const { gasLimitMultiplier, raffleContract, raffleV1Address, web3 } = await this.$getContracts()

		const transactionData = {
			from: publicAddress,
		}

		const contractMethod = await (raffleContract._address.toLowerCase() === raffle.contractAddress
			? raffleContract
			: raffleV1Address
		).methods.claimPrize(this.convertNumberToBigNumber(raffle.raffleId))

		return this.$executeContractMethod({
			contractMethod,
			transactionData,
			web3,
			gasLimitMultiplier,
		})
	})

	inject('claimFundsRaffle', async function (raffle, publicAddress) {
		await this.$switchToPolygonNetwork()

		const { gasLimitMultiplier, raffleContract, raffleV1Address, web3 } = await this.$getContracts()

		const transactionData = {
			from: publicAddress,
		}

		const contractMethod = await (raffleContract._address.toLowerCase() === raffle.contractAddress
			? raffleContract
			: raffleV1Address
		).methods.claimFunds(this.convertNumberToBigNumber(raffle.raffleId))

		return this.$executeContractMethod({
			contractMethod,
			transactionData,
			web3,
			gasLimitMultiplier,
		})
	})

	inject('recoverNFTRaffle', async function (raffle, publicAddress) {
		await this.$switchToPolygonNetwork()

		const { gasLimitMultiplier, raffleContract, raffleV1Address, web3 } = await this.$getContracts()

		const transactionData = {
			from: publicAddress,
		}

		const contractMethod = await (raffleContract._address.toLowerCase() === raffle.contractAddress
			? raffleContract
			: raffleV1Address
		).methods.recoverNFT(this.convertNumberToBigNumber(raffle.raffleId))

		return this.$executeContractMethod({
			contractMethod,
			transactionData,
			web3,
			gasLimitMultiplier,
		})
	})
}
