
export default {
	data() {
		return {
			defaultClass: this.$device.isMobile ? 'col-10' : 'col-3',
		}
	},
	props: {
		title: {
			type: String,
		},
		displayModal: {
			type: Boolean,
			required: true,
		},
		modalClass: {
			type: String,
		},
		closeButtonHidden: {
			type: Boolean,
			default: false,
		},
		disableBackdropClose: {
			type: Boolean,
			default: false,
		},
		scrollable: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		onClose() {
			if (!this.disableBackdropClose) this.$emit('onClose')
		},
	},
}
