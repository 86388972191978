import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cc743186 = () => interopDefault(import('../pages/characters/index.vue' /* webpackChunkName: "pages/characters/index" */))
const _056cf54a = () => interopDefault(import('../pages/connect.vue' /* webpackChunkName: "pages/connect" */))
const _001a7e53 = () => interopDefault(import('../pages/consumables/index.vue' /* webpackChunkName: "pages/consumables/index" */))
const _5fdbd1b8 = () => interopDefault(import('../pages/dev/index.vue' /* webpackChunkName: "pages/dev/index" */))
const _a54f879c = () => interopDefault(import('../pages/mint.vue' /* webpackChunkName: "pages/mint" */))
const _821ea770 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _ad7ae2ee = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _17ed1e21 = () => interopDefault(import('../pages/quests.vue' /* webpackChunkName: "pages/quests" */))
const _7fbee2ae = () => interopDefault(import('../pages/raffles/index.vue' /* webpackChunkName: "pages/raffles/index" */))
const _557532f8 = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _95751e4c = () => interopDefault(import('../pages/staking/index.vue' /* webpackChunkName: "pages/staking/index" */))
const _48c43926 = () => interopDefault(import('../pages/swap/index.vue' /* webpackChunkName: "pages/swap/index" */))
const _18daa4e2 = () => interopDefault(import('../pages/characters/models.vue' /* webpackChunkName: "pages/characters/models" */))
const _063ff444 = () => interopDefault(import('../pages/dev/coin/index.vue' /* webpackChunkName: "pages/dev/coin/index" */))
const _7a5404ec = () => interopDefault(import('../pages/dev/mint.vue' /* webpackChunkName: "pages/dev/mint" */))
const _1f8bdb18 = () => interopDefault(import('../pages/dev/models.vue' /* webpackChunkName: "pages/dev/models" */))
const _b6cb6a16 = () => interopDefault(import('../pages/dev/raffle/index.vue' /* webpackChunkName: "pages/dev/raffle/index" */))
const _1b7dbf20 = () => interopDefault(import('../pages/dev/whitelist/index.vue' /* webpackChunkName: "pages/dev/whitelist/index" */))
const _c05c8460 = () => interopDefault(import('../pages/raffles/create.vue' /* webpackChunkName: "pages/raffles/create" */))
const _7e0590d9 = () => interopDefault(import('../pages/raffles/leaderboard.vue' /* webpackChunkName: "pages/raffles/leaderboard" */))
const _c1b0eba8 = () => interopDefault(import('../pages/staking/rewards.vue' /* webpackChunkName: "pages/staking/rewards" */))
const _11119350 = () => interopDefault(import('../pages/swap/create.vue' /* webpackChunkName: "pages/swap/create" */))
const _7b8d3403 = () => interopDefault(import('../pages/dev/raffle/updateFees.vue' /* webpackChunkName: "pages/dev/raffle/updateFees" */))
const _6844c0fa = () => interopDefault(import('../pages/raffles/u/_publicAddress.vue' /* webpackChunkName: "pages/raffles/u/_publicAddress" */))
const _2161b896 = () => interopDefault(import('../pages/raffles/_id.vue' /* webpackChunkName: "pages/raffles/_id" */))
const _838b65e4 = () => interopDefault(import('../pages/swap/_id.vue' /* webpackChunkName: "pages/swap/_id" */))
const _a88acd2a = () => interopDefault(import('../pages/consumables/_id/consume/index.vue' /* webpackChunkName: "pages/consumables/_id/consume/index" */))
const _2a8a74ef = () => interopDefault(import('../pages/consumables/_id/previews.vue' /* webpackChunkName: "pages/consumables/_id/previews" */))
const _1966429a = () => interopDefault(import('../pages/consumables/_id/consume/create.vue' /* webpackChunkName: "pages/consumables/_id/consume/create" */))
const _a3923c6c = () => interopDefault(import('../pages/consumables/_id/consume/processing.vue' /* webpackChunkName: "pages/consumables/_id/consume/processing" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/characters",
    component: _cc743186,
    name: "characters"
  }, {
    path: "/connect",
    component: _056cf54a,
    name: "connect"
  }, {
    path: "/consumables",
    component: _001a7e53,
    name: "consumables"
  }, {
    path: "/dev",
    component: _5fdbd1b8,
    name: "dev"
  }, {
    path: "/mint",
    component: _a54f879c,
    name: "mint"
  }, {
    path: "/notifications",
    component: _821ea770,
    name: "notifications"
  }, {
    path: "/profile",
    component: _ad7ae2ee,
    name: "profile"
  }, {
    path: "/quests",
    component: _17ed1e21,
    name: "quests"
  }, {
    path: "/raffles",
    component: _7fbee2ae,
    name: "raffles"
  }, {
    path: "/rewards",
    component: _557532f8,
    name: "rewards"
  }, {
    path: "/staking",
    component: _95751e4c,
    name: "staking"
  }, {
    path: "/swap",
    component: _48c43926,
    name: "swap"
  }, {
    path: "/characters/models",
    component: _18daa4e2,
    name: "characters-models"
  }, {
    path: "/dev/coin",
    component: _063ff444,
    name: "dev-coin"
  }, {
    path: "/dev/mint",
    component: _7a5404ec,
    name: "dev-mint"
  }, {
    path: "/dev/models",
    component: _1f8bdb18,
    name: "dev-models"
  }, {
    path: "/dev/raffle",
    component: _b6cb6a16,
    name: "dev-raffle"
  }, {
    path: "/dev/whitelist",
    component: _1b7dbf20,
    name: "dev-whitelist"
  }, {
    path: "/raffles/create",
    component: _c05c8460,
    name: "raffles-create"
  }, {
    path: "/raffles/leaderboard",
    component: _7e0590d9,
    name: "raffles-leaderboard"
  }, {
    path: "/staking/rewards",
    component: _c1b0eba8,
    name: "staking-rewards"
  }, {
    path: "/swap/create",
    component: _11119350,
    name: "swap-create"
  }, {
    path: "/dev/raffle/updateFees",
    component: _7b8d3403,
    name: "dev-raffle-updateFees"
  }, {
    path: "/raffles/u/:publicAddress?",
    component: _6844c0fa,
    name: "raffles-u-publicAddress"
  }, {
    path: "/raffles/:id",
    component: _2161b896,
    name: "raffles-id"
  }, {
    path: "/swap/:id",
    component: _838b65e4,
    name: "swap-id"
  }, {
    path: "/consumables/:id/consume",
    component: _a88acd2a,
    name: "consumables-id-consume"
  }, {
    path: "/consumables/:id/previews",
    component: _2a8a74ef,
    name: "consumables-id-previews"
  }, {
    path: "/consumables/:id/consume/create",
    component: _1966429a,
    name: "consumables-id-consume-create"
  }, {
    path: "/consumables/:id/consume/processing",
    component: _a3923c6c,
    name: "consumables-id-consume-processing"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
