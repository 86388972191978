export const state = () => ({
	user: null,
	chain: null,
})

export const mutations = {
	SET_USER(state, user) {
		if (user) {
			state.user = { ...user }
			return
		}

		state.user = null
	},
	SET_CHAIN(state, chain) {
		state.chain = chain
	},
}

export const actions = {
	async setUser({ commit }, userData) {
		commit('SET_USER', userData)
	},

	async loginSolana({ commit }, publicAddress) {
		const { solana } = window

		const nonceRequest = await this.$axios.get(`/api/auth/${publicAddress}/get-nonce`).then(res => res.data)

		const encodedMessage = new TextEncoder().encode(nonceRequest.message)
		const signature = await solana.signMessage(encodedMessage, 'utf8')

		const { data } = await this.$axios.post('/api/auth/login', {
			publicAddress,
			signature: JSON.stringify(signature),
			nonce: nonceRequest.nonce,
			blockchain: 'solana',
		})

		commit('SET_USER', data.user)
	},

	async loginEthereum({ commit }, wallet) {
		const nonceRequest = await this.$axios.get(`/api/auth/${wallet.publicAddress}/get-nonce`).then(res => res.data)

		const signature = await wallet.provider.request({
			method: 'personal_sign',
			params: [nonceRequest.message, wallet.publicAddress],
		})

		const { data } = await this.$axios.post('/api/auth/login', {
			publicAddress: wallet.publicAddress,
			signature,
			nonce: nonceRequest.nonce,
			blockchain: 'ethereum',
		})

		commit('SET_USER', data.user)
	},

	/**
	 * @reference https://github.com/ethereum-lists/chains
	 */
	async updateChain({ commit }, chainId) {
		commit('SET_CHAIN', chainId)
	},

	async logout({ commit, state }) {
		if (state.user) {
			await this.$axios.post('/api/auth/logout')

			if (this.$router.currentRoute.path !== '/') {
				await new Promise(resolve => this.$router.push('/', resolve))
			}

			commit('SET_USER', null)
		}
	},
}
