
export default {
	props: {
		displayLoadingContent: {
			type: Boolean,
			default: false,
			required: true,
		},
		textLoading: {
			type: String,
		},
		loaderBlack: {
			type: Boolean,
			default: false,
		},
		loaderSize: String,
		loaderClass: String,
	},
	computed: {
		spinnerSize() {
			switch (this.loaderSize) {
				case 'sm':
					return 'loader-sm'

				case 'lg':
					return 'loader-lg'

				case 'xlg':
					return 'loader-xlg'
				case 'special':
					return 'loader-special'
				default:
					return 'loader-md'
			}
		},
	},
}
