
export default {
	head() {
		return {
			title: `Reality Kingdom - 404`,
			meta: [
				{
					property: 'og:title',
					content: 'Reality Kingdom - 404',
				},
			],
		}
	},
}
