/**
 * Handles the ethereum based wallets and networks across the application.
 * This code it's running all the time.
 */

export default ({ $config: { isTestNet }, store }, inject) => {
	if (window.ethereum) {
		const watchEthereumAccountChanges = () => {
			try {
				ethereum.on('accountsChanged', async accounts => {
					if (
						store.state.auth.user &&
						(!accounts.length || store.state.auth.user.publicAddress !== accounts[0])
					) {
						/**
						 * @todo We need to find a way to switch the user account if he already signed a message before.
						 */
						await store.dispatch('auth/logout')
					}
				})
			} catch (error) {
				console.error(error)
				store.dispatch('auth/logout')
			}
		}

		const getConnectedChain = async () => {
			try {
				const chainId = await window.ethereum.request({ method: 'eth_chainId' })

				checkConnectedChain(chainId)
			} catch (error) {
				console.error(error)
			}
		}

		const checkConnectedChain = chainId => {
			store.dispatch('auth/updateChain', parseInt(chainId))
		}

		const watchChainChanges = () => {
			ethereum.on('chainChanged', checkConnectedChain)
		}

		const getConnectedWallets = async () => {
			try {
				const accounts = await window.ethereum.request({ method: 'eth_accounts' })
				if (!accounts.length || !store.state.auth.user || store.state.auth.user.publicAddress !== accounts[0]) {
					return store.dispatch('auth/logout')
				}

				await getConnectedChain()
			} catch (error) {
				console.error(error)
				store.dispatch('auth/logout')
			}
		}

		const isMetamaskUnlocked = () => {
			return ethereum._metamask.isUnlocked()
		}

		const connectToDefaultWallet = async providerName => {
			if (typeof window.ethereum !== 'undefined') {
				let provider = window.ethereum

				if (window.ethereum.providers && window.ethereum.providers.length) {
					if (providerName === 'metamask') {
						provider = window.ethereum.providers.find(provider => provider.isMetaMask)
					}

					if (providerName === 'coinbase') {
						provider = window.ethereum.providers.find(provider => provider.isCoinbaseWallet)
					}

					if (providerName === 'phantomWallet') {
						provider = window.ethereum.providers.find(provider => provider.isPhantomWalletWallet)
					}
				}

				const accounts = await provider.request({
					method: 'eth_requestAccounts',
				})

				await store.dispatch('auth/loginEthereum', {
					publicAddress: accounts[0],
					provider,
				})
			}
		}

		const switchToPolygonNetwork = async () => {
			const chainId = isTestNet ? 80002 : 137

			if (window.ethereum.networkVersion === chainId.toString()) {
				return
			}

			const chainIdsInHex = {
				137: '0x89',
				80002: '0x13881',
			}

			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: chainIdsInHex[chainId] }],
				})
			} catch (err) {
				if (err.code === 4902) {
					const rpcUrls = {
						137: 'https://polygon-rpc.com/',
						80002: 'https://polygon-polygonAmoy.gateway.tenderly.co',
					}

					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainName: isTestNet ? 'Polygon polygonAmoy' : 'Polygon Mainnet',
								chainId: chainIdsInHex[chainId],
								nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
								rpcUrls: [rpcUrls[chainId]],
							},
						],
					})
				}

				console.error(err)
			}

			return new Promise((resolve, reject) =>
				setTimeout(() => switchToPolygonNetwork().then(resolve).catch(reject), 500)
			)
		}

		/**
		 * Startup methods
		 */
		getConnectedChain()
		getConnectedWallets()

		/**
		 * Injections
		 */
		inject('connectToDefaultWallet', connectToDefaultWallet)
		inject('isMetamaskUnlocked', isMetamaskUnlocked)
		inject('getConnectedChain', getConnectedChain)
		inject('switchToPolygonNetwork', switchToPolygonNetwork)

		/**
		 * Event listeners
		 */
		window.addEventListener('load', watchEthereumAccountChanges)
		window.addEventListener('load', watchChainChanges)
	}
}
