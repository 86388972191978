export const Accordion = () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const BuyRaffleTicketModal = () => import('../../components/BuyRaffleTicketModal.vue' /* webpackChunkName: "components/buy-raffle-ticket-modal" */).then(c => wrapFunctional(c.default || c))
export const BuyScrollModal = () => import('../../components/BuyScrollModal.vue' /* webpackChunkName: "components/buy-scroll-modal" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInput = () => import('../../components/CheckoutInput.vue' /* webpackChunkName: "components/checkout-input" */).then(c => wrapFunctional(c.default || c))
export const Cropper = () => import('../../components/Cropper.vue' /* webpackChunkName: "components/cropper" */).then(c => wrapFunctional(c.default || c))
export const DiscordHelpIcon = () => import('../../components/DiscordHelpIcon.vue' /* webpackChunkName: "components/discord-help-icon" */).then(c => wrapFunctional(c.default || c))
export const DownloadButton = () => import('../../components/DownloadButton.vue' /* webpackChunkName: "components/download-button" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const EmbedNFT = () => import('../../components/EmbedNFT.vue' /* webpackChunkName: "components/embed-n-f-t" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GameHeader = () => import('../../components/GameHeader.vue' /* webpackChunkName: "components/game-header" */).then(c => wrapFunctional(c.default || c))
export const GoldenBorder = () => import('../../components/GoldenBorder.vue' /* webpackChunkName: "components/golden-border" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const KnightFrame = () => import('../../components/KnightFrame.vue' /* webpackChunkName: "components/knight-frame" */).then(c => wrapFunctional(c.default || c))
export const LazyImage = () => import('../../components/LazyImage.vue' /* webpackChunkName: "components/lazy-image" */).then(c => wrapFunctional(c.default || c))
export const LightBox = () => import('../../components/LightBox.vue' /* webpackChunkName: "components/light-box" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MintPageVideoThumbnail = () => import('../../components/MintPageVideoThumbnail.vue' /* webpackChunkName: "components/mint-page-video-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const MusicPlayer = () => import('../../components/MusicPlayer.vue' /* webpackChunkName: "components/music-player" */).then(c => wrapFunctional(c.default || c))
export const NftSelectionModal = () => import('../../components/NftSelectionModal.vue' /* webpackChunkName: "components/nft-selection-modal" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const ProcessingText = () => import('../../components/ProcessingText.vue' /* webpackChunkName: "components/processing-text" */).then(c => wrapFunctional(c.default || c))
export const ProfileMenuItem = () => import('../../components/ProfileMenuItem.vue' /* webpackChunkName: "components/profile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ProfilePictureAndCover = () => import('../../components/ProfilePictureAndCover.vue' /* webpackChunkName: "components/profile-picture-and-cover" */).then(c => wrapFunctional(c.default || c))
export const RealityCatsFrame = () => import('../../components/RealityCatsFrame.vue' /* webpackChunkName: "components/reality-cats-frame" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTop = () => import('../../components/ScrollToTop.vue' /* webpackChunkName: "components/scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const SoulCard = () => import('../../components/SoulCard.vue' /* webpackChunkName: "components/soul-card" */).then(c => wrapFunctional(c.default || c))
export const SparklyButton = () => import('../../components/SparklyButton.vue' /* webpackChunkName: "components/sparkly-button" */).then(c => wrapFunctional(c.default || c))
export const SpeechBox = () => import('../../components/SpeechBox.vue' /* webpackChunkName: "components/speech-box" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubble = () => import('../../components/SpeechBubble.vue' /* webpackChunkName: "components/speech-bubble" */).then(c => wrapFunctional(c.default || c))
export const SwapNftCard = () => import('../../components/SwapNftCard.vue' /* webpackChunkName: "components/swap-nft-card" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const TradeBox = () => import('../../components/TradeBox.vue' /* webpackChunkName: "components/trade-box" */).then(c => wrapFunctional(c.default || c))
export const UpdatesModal = () => import('../../components/UpdatesModal.vue' /* webpackChunkName: "components/updates-modal" */).then(c => wrapFunctional(c.default || c))
export const UploadedImage = () => import('../../components/UploadedImage.vue' /* webpackChunkName: "components/uploaded-image" */).then(c => wrapFunctional(c.default || c))
export const UserEXPProgress = () => import('../../components/UserEXPProgress.vue' /* webpackChunkName: "components/user-e-x-p-progress" */).then(c => wrapFunctional(c.default || c))
export const YoutubeLightbox = () => import('../../components/YoutubeLightbox.vue' /* webpackChunkName: "components/youtube-lightbox" */).then(c => wrapFunctional(c.default || c))
export const IconsBookFrameMain = () => import('../../components/Icons/BookFrameMain.vue' /* webpackChunkName: "components/icons-book-frame-main" */).then(c => wrapFunctional(c.default || c))
export const IconsBookFrameSides = () => import('../../components/Icons/BookFrameSides.vue' /* webpackChunkName: "components/icons-book-frame-sides" */).then(c => wrapFunctional(c.default || c))
export const RaffleCard = () => import('../../components/Raffle/Card.vue' /* webpackChunkName: "components/raffle-card" */).then(c => wrapFunctional(c.default || c))
export const RaffleEndedModal = () => import('../../components/Raffle/EndedModal.vue' /* webpackChunkName: "components/raffle-ended-modal" */).then(c => wrapFunctional(c.default || c))
export const RaffleFloorBadge = () => import('../../components/Raffle/FloorBadge.vue' /* webpackChunkName: "components/raffle-floor-badge" */).then(c => wrapFunctional(c.default || c))
export const RaffleLeaderboard = () => import('../../components/Raffle/Leaderboard.vue' /* webpackChunkName: "components/raffle-leaderboard" */).then(c => wrapFunctional(c.default || c))
export const RaffleMedal = () => import('../../components/Raffle/Medal.vue' /* webpackChunkName: "components/raffle-medal" */).then(c => wrapFunctional(c.default || c))
export const RaffleProfileEmptyState = () => import('../../components/Raffle/ProfileEmptyState.vue' /* webpackChunkName: "components/raffle-profile-empty-state" */).then(c => wrapFunctional(c.default || c))
export const RaffleRarityBadge = () => import('../../components/Raffle/RarityBadge.vue' /* webpackChunkName: "components/raffle-rarity-badge" */).then(c => wrapFunctional(c.default || c))
export const RaffleRecoveredNFTModal = () => import('../../components/Raffle/RecoveredNFTModal.vue' /* webpackChunkName: "components/raffle-recovered-n-f-t-modal" */).then(c => wrapFunctional(c.default || c))
export const RaffleShareTwitter = () => import('../../components/Raffle/ShareTwitter.vue' /* webpackChunkName: "components/raffle-share-twitter" */).then(c => wrapFunctional(c.default || c))
export const RaffleWhitelistedCollections = () => import('../../components/Raffle/WhitelistedCollections.vue' /* webpackChunkName: "components/raffle-whitelisted-collections" */).then(c => wrapFunctional(c.default || c))
export const RaffleWinnerBadge = () => import('../../components/Raffle/WinnerBadge.vue' /* webpackChunkName: "components/raffle-winner-badge" */).then(c => wrapFunctional(c.default || c))
export const RaffleWithdrawnModal = () => import('../../components/Raffle/WithdrawnModal.vue' /* webpackChunkName: "components/raffle-withdrawn-modal" */).then(c => wrapFunctional(c.default || c))
export const StakingCard = () => import('../../components/Staking/Card.vue' /* webpackChunkName: "components/staking-card" */).then(c => wrapFunctional(c.default || c))
export const StakingRewardCard = () => import('../../components/Staking/RewardCard.vue' /* webpackChunkName: "components/staking-reward-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
