export default async function ({ redirect, store, $axios, route }) {
	try {
		if (route.name.startsWith('consumables')) {
			const consumable = await $axios.get(`/api/consumables/${route.params.id}`).then(res => res.data)
			if (consumable.user !== store.state.auth.user._id) {
				return redirect('/profile')
			}
		}

		if (route.name.startsWith('swap')) {
			const offer = await $axios.get(`/api/swap/offers/${route.params.id}`).then(res => res.data)
			if (
				offer.to !== store.state.auth.user.publicAddress &&
				offer.user !== store.state.auth.user.publicAddress
			) {
				return redirect('/profile')
			}
		}
	} catch (error) {
		// Some route middlewares may return 403 and interrupt the middleware due to wrong user. Swap middlewares don't do that AFAIK, but consumable routes do.
		return redirect('/profile')
	}
}
