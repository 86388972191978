
export default {
	async fetch() {
		if (this.showNetworkData) {
			const [networkData, { raffleAddress }, latestRaffleResults] = await Promise.all([
				this.$axios
					.get(`/api/transactions/raffles-header-data/${this.$config.isTestNet ? 'polygonAmoy' : 'polygon'}`)
					.then(res => res.data),

				this.$axios
					.get(`/api/files/contracts/${this.$config.isTestNet ? 'polygonAmoy' : 'polygon'}`)
					.then(res => res.data),

				this.$axios.get('/api/raffles/winners/latest').then(res => res.data),
			])

			this.latestRaffleResults = latestRaffleResults

			this.networkData[0].value = `${networkData.activeRafflesCount}`
			this.networkData[1].value = `${this.formatNumber(parseInt(networkData.totalVolume), false)} MATIC`
			this.networkData[2].value = `$${networkData.maticValueToUsd}`
			this.networkData[3].value = `${networkData.gasFee} Gwei`
			this.networkData[4].link = `https://polygonscan.com/address/${raffleAddress}`

			this.loadingNetworkData = false
		}

		if (this.$store.state.auth.user && this.$store.state.auth.user._id) {
			try {
				const [notification, hasAvailableConsumable] = await Promise.all([
					this.$axios.get(`/api/notifications/latest-unread`).then(res => res.data),
					this.$axios
						.get('/api/consumables/has-available-consumables')
						.then(res => res.data.hasAvailableConsumable),
				])

				this.notification = notification
				this.hasAvailableConsumable = hasAvailableConsumable
			} catch (error) {
				console.error(error)
			}

			if (!process.server) {
				this.getRealityCoinBalance()
			}
		}
	},
	props: {
		isConnectPage: {
			type: Boolean,
			default: false,
		},
		exposeNotification: {
			type: Boolean,
			default: false,
		},
		hideNotification: {
			type: Boolean,
			default: false,
		},
		showNetworkData: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			openConnectModal: false,
			currentWallet: null,
			deniedPermission: {
				metamask: false,
				coinbase: false,
			},
			loading: {
				metamask: false,
				phantom: false,
				coinbase: false,
			},
			walletString: '',
			browseSelect: 'reality',
			isMobileMenuOpen: false,
			notification: null,
			hasAvailableConsumable: false,
			chains: {
				80002: 'polygonAmoy',
				137: 'polygon',
				1: 'ethereum',
			},

			networkData: [
				{
					icon: 'confirmation_number',
					label: 'Active Raffles: ',
					value: null,
					iconType: 'icon',
					dataType: 'data',
				},
				{
					icon: 'trending_up',
					label: 'Volume total: ',
					value: null,
					iconType: 'icon',
					dataType: 'data',
				},
				{
					icon: '/svg/PolygonWhite.svg',
					label: 'MATIC/USD: ',
					value: null,
					iconType: 'image',
					dataType: 'data',
				},
				{
					icon: 'local_gas_station',
					label: 'Gas fee: ',
					value: null,
					iconType: 'icon',
					dataType: 'data',
				},
				{
					icon: '/svg/PolygonWhite.svg',
					value: 'Smart Contract',
					iconType: 'image',
					dataType: 'link',
					link: '',
				},
				{
					icon: '/svg/Gitbook.svg',
					value: 'GitBook',
					iconType: 'image',
					dataType: 'link',
					link: 'https://realitykingdom.gitbook.io/info/solutions/rk-raffle',
				},
			],
			networkDataMobileIntervalInMs: 3000,
			informationDisplayIntervalInMs: 5000,
			informationDisplay: 'networkData',
			loadingNetworkData: true,
			latestRaffleResults: null,
			infoBannerCycleInterval: null,
			latestRaffleCycleInterval: null,
			networkDataIndex: 0,
			latestRaffleResultIndex: 0,
			infoBannerIndex: 0,
			userRkCoinBalance: 0,
			loadingUserRkBalance: true,
		}
	},
	methods: {
		toggleMobileMenu() {
			this.isMobileMenuOpen = !this.isMobileMenuOpen
		},
		async switchNetwork() {
			try {
				await this.$switchToPolygonNetwork()
			} catch (error) {
				this.$sentryCaptureException(error)
				this.alert = this.$errorHandler(error)
			}
		},
		truncateWallet() {
			if (this.loggedUser.publicAddress) {
				return this.truncateAddress(this.loggedUser.publicAddress)
			}
		},

		async logoutUser() {
			this.$store.dispatch('auth/logout')
		},

		async connectWallet(wallet) {
			this.sendGTagEvent(`connect_wallet_${wallet}_intent`)
			const wallets = this.getAvailableWallets()
			this.deniedPermission[wallet] = false
			this.loading[wallet] = true

			if (this.isWalletInstalled(wallet)) {
				try {
					await this.$switchToPolygonNetwork()

					await this.$connectToDefaultWallet(wallet)

					this.sendGTagEvent(`connect_wallet_${wallet}_successful`)
					this.currentWallet = wallet
					this.$router.push('/profile')
				} catch (error) {
					this.sendGTagEvent(`connect_wallet_${wallet}_error`)
					this.$sentryCaptureException(error)
					this.alert = this.$errorHandler(error)
					this.deniedPermission[wallet] = true
				}

				return (this.loading[wallet] = false)
			}

			if (this.$device.isDesktop) {
				window.open(wallets[wallet].downloadUrls.desktop, '_blank')
			} else {
				const a = document.createElement('a')
				a.href = wallets[wallet].downloadUrls.mobile
				a.target = '_self'
				document.body.appendChild(a)
				a.click()
				a.remove()
			}
			this.loading[wallet] = false
		},

		cycleNetworkData() {
			this.networkDataIndex = (this.networkDataIndex + 1) % this.networkData.length
		},

		cycleRaffleResultData() {
			this.latestRaffleResultIndex = (this.latestRaffleResultIndex + 1) % this.latestRaffleResults.length
		},

		cycleInformationDisplay() {
			if (!this.latestRaffleResults) return

			const totalBannerDisplayItems = this.latestRaffleResults.length + 1

			this.infoBannerIndex = (this.infoBannerIndex + 1) % totalBannerDisplayItems

			this.infoBannerIndex === 0
				? (this.informationDisplay = 'networkData')
				: (this.informationDisplay = 'latestRaffleResults')
		},

		startInformationCycle() {
			if (this.$device.isMobile) {
				return (this.infoBannerCycleInterval = setInterval(
					this.cycleNetworkData,
					this.networkDataMobileIntervalInMs
				))
			}

			this.infoBannerCycleInterval = setInterval(
				this.cycleInformationDisplay,
				this.informationDisplayIntervalInMs
			)

			this.latestRaffleCycleInterval = setInterval(
				this.cycleRaffleResultData,
				this.informationDisplayIntervalInMs
			)
		},
		async getRealityCoinBalance() {
			if (!this.loggedUser) return

			this.loadingUserRkBalance = true

			try {
				const { coinContract } = await this.$getContracts()

				const balance = await coinContract.methods.balanceOf(this.loggedUser.publicAddress).call()
				this.userRkCoinBalance = this.convertWeiToEther(balance)
			} catch (error) {
				this.$sentryCaptureException(error)
			}

			this.loadingUserRkBalance = false
		},
	},
	computed: {
		loggedUser() {
			return this.$store.state.auth.user
		},
		connectedChain() {
			return this.$store.state.auth.chain
		},
		isConnectedToTheCorrectChain() {
			const blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon'

			return this.chains[this.connectedChain] === blockchain
		},
		displayAlert() {
			if (!this.connectedChain) {
				return false
			}

			const blockchain = this.$config.isTestNet ? 'polygonAmoy' : 'polygon'
			return this.chains[this.connectedChain] ? this.chains[this.connectedChain] !== blockchain : true
		},
	},
	watch: {
		notification() {
			if (this.exposeNotification) {
				this.$emit('hasNotification', this.notification)
			}
		},
		isConnectedToTheCorrectChain() {
			if (this.isConnectedToTheCorrectChain) {
				this.getRealityCoinBalance()
			}
		},
	},
	mounted() {
		if (!this.showNetworkData) return
		this.startInformationCycle()
	},

	beforeDestroy() {
		clearInterval(this.infoBannerCycleInterval)
		clearInterval(this.latestRaffleCycleInterval)
	},
}
