export default async function ({ redirect, store, $axios, route }) {
	try {
		const consumable = await $axios.get(`/api/consumables/${route.params.id}`).then(res => res.data)

		if (!consumable || consumable.user !== store.state.auth.user._id) {
			return redirect('/consumables')
		}
	} catch (error) {
		return redirect('/consumables')
	}
}
