const middleware = {}

middleware['authenticateAdmin'] = require('../middleware/authenticateAdmin.js')
middleware['authenticateAdmin'] = middleware['authenticateAdmin'].default || middleware['authenticateAdmin']

middleware['authenticateUser'] = require('../middleware/authenticateUser.js')
middleware['authenticateUser'] = middleware['authenticateUser'].default || middleware['authenticateUser']

middleware['authorizeConsumable'] = require('../middleware/authorizeConsumable.js')
middleware['authorizeConsumable'] = middleware['authorizeConsumable'].default || middleware['authorizeConsumable']

middleware['authorizeUserInteraction'] = require('../middleware/authorizeUserInteraction.js')
middleware['authorizeUserInteraction'] = middleware['authorizeUserInteraction'].default || middleware['authorizeUserInteraction']

middleware['getCurrentUser'] = require('../middleware/getCurrentUser.js')
middleware['getCurrentUser'] = middleware['getCurrentUser'].default || middleware['getCurrentUser']

middleware['verifySouls'] = require('../middleware/verifySouls.js')
middleware['verifySouls'] = middleware['verifySouls'].default || middleware['verifySouls']

export default middleware
