export default async ({ store }, inject) => {
	if (window.solana) {
		const connectToPhantomWallet = async () => {
			const response = await window.solana.connect()

			const account = response.publicKey.toString()

			await dispatchToLogin(account)
		}

		const dispatchToLogin = async account => {
			await store.dispatch('auth/loginSolana', account)
		}

		/**
		 * Injections
		 */
		inject('connectToPhantomWallet', connectToPhantomWallet)
		inject('phantomInstance', window.solana)
	}
}