import Vue from 'vue'

if (!Vue.__utils_mixin__) {
	Vue.mixin({
		data() {
			return {
				web3: null,
			}
		},
		methods: {
			isWalletInstalled(wallet) {
				const { coinbaseWalletExtension, ethereum, solana } = window

				switch (wallet) {
					case 'metamask':
						return Boolean(ethereum && ethereum.isMetaMask)
					case 'coinbase':
						return Boolean(coinbaseWalletExtension && coinbaseWalletExtension.isCoinbaseWallet)
					case 'phantom':
						return Boolean(solana && solana.isPhantom)
				}

				return false
			},
			isValidFile(fileType) {
				const fileTypes = [...this.$config.fileTypes.images]

				return fileTypes.indexOf(fileType.toLowerCase()) !== -1
			},
			getAvailableWallets() {
				/**
				 * @todo Maybe add the icon name and stuff here too?
				 */
				return {
					coinbase: {
						name: 'Coinbase',
						downloadUrls: {
							desktop: 'https://www.coinbase.com/wallet/downloads',
							mobile: `https://go.cb-w.com/dapp?cb_url=${window.location.host}`,
						},
					},
					metamask: {
						name: 'Metamask',
						downloadUrls: {
							desktop: 'https://metamask.io/download/',
							mobile: `https://metamask.app.link/dapp/${window.location.host}`,
						},
					},
					phantom: {
						name: 'Phantom',
						downloadUrls: {
							desktop: 'https://phantom.app/download',
							mobile: `https://phantom.app/ul/browse/${window.location.host}`,
						},
					},
				}
			},
			convertNumberToWei(value) {
				if (!this.web3) return

				return this.web3.utils.toWei(value.toString(), 'ether')
			},
			convertNumberToBigNumber(value) {
				if (!this.web3) return

				return this.web3.utils.toBN(value)
			},
			convertWeiToEther(value) {
				if (!this.web3) return

				return this.web3.utils.fromWei(value)
			},
			isValidAddress(address) {
				if (!this.web3) return

				return this.web3.utils.isAddress(address)
			},
			getUserBalance(publicAddress) {
				if (!this.web3) return

				return this.web3.eth.getBalance(publicAddress)
			},
			removeSpecialChars(string) {
				return string.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
			},
			getAbsoluteUrl(to) {
				if (process.browser) {
					const path = this.$nuxt.$router.resolve(to).href
					return window.location.origin + path
				}
			},
			truncateText(text, length, suffix) {
				if (text) {
					if (text.length > length) {
						return text.substring(0, length) + suffix
					} else {
						return text
					}
				}
			},
			copyText(text) {
				navigator.clipboard
					.writeText(text)
					.then(() => {
						console.log('Text copied to clipboard')
					})
					.catch(error => {
						console.error('Error copying text to clipboard:', error)
					})
			},
			calculateTime(from, to) {
				const days = this.$dayjs(to).diff(from, 'day')
				const hours = this.$dayjs(to).diff(from, 'hour') % 24
				const minutes = this.$dayjs(to).diff(from, 'minute') % 60
				const seconds = this.$dayjs(to).diff(from, 'second') % 60

				let result = ''

				if (days > 0) {
					result += `${days} d / `
				}

				if (hours < 24 && hours >= 0) {
					result += `${hours} h /`
				}

				return `${result} ${minutes} min / ${seconds} s`
			},
			getBlockchainNetwork(network) {
				const mainnet = {
					ethereum: 'ETH',
					polygon: 'MATIC',
					solana: 'SOL',
				}

				const testnet = {
					ropsten: 'ETH',
					polygonAmoy: 'MATIC',
					solana: 'SOL',
				}

				const blockchainScan = !this.$config.isTestNet ? mainnet : testnet

				return blockchainScan[network]
			},
			formatCurrency(value, withZeros = true) {
				if (withZeros) {
					let val = (value / 1).toFixed(2)
					return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
				}

				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
			},
			formatNumber(value) {
				if (value > 999999999) {
					return Math.sign(value) * (Math.abs(value) / 1000000000).toFixed(2) + 'B'
				}

				if (value > 999999) {
					return Math.sign(value) * (Math.abs(value) / 1000000).toFixed(2) + 'M'
				}

				if (value > 999) {
					return Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + 'K'
				}

				return value
			},
			async convertedValue(networkPrefix, value) {
				const { data } = await this.$axios
					.get(`https://api.coinbase.com/v2/prices/${this.getBlockchainNetwork(networkPrefix)}-USD/spot/`)
					.then(res => res.data)

				return this.formatCurrency(value * data.amount)
			},
			downloadImage(fileUrl) {
				fetch(fileUrl)
					.then(response => response.blob())
					.then(blob => {
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(blob)
						link.download = 'image.jpg'
						link.click()
						link.remove()
					})
					.catch(error => {
						console.error(error)
					})
			},
			getOpenseaLink(contractAddress, tokenId) {
				return this.$config.isTestNet
					? `https://testnets.opensea.io/assets/polygonAmoy/${contractAddress}/${tokenId}`
					: `https://opensea.io/assets/matic/${contractAddress}/${tokenId}`
			},
			getTransactionLink(transactionAddress) {
				return this.$config.isTestNet
					? `https://polygonAmoy.polygonscan.com/tx/${transactionAddress}`
					: `https://polygonscan.com/tx/${transactionAddress}`
			},
			getMagicEdenLink(contractAddress, tokenId) {
				return `https://magiceden.io/item-details/polygon/${contractAddress}/${tokenId}`
			},
			getNetworkScanLink(contractAddress, tokenId = null) {
				if (tokenId) {
					return this.$config.isTestNet
						? `https://polygonAmoy.polygonscan.com/token/${contractAddress}?a=${tokenId}`
						: `https://polygonscan.com/token/${contractAddress}?a=${tokenId}`
				}

				return this.$config.isTestNet
					? `https://polygonAmoy.polygonscan.com/address/${contractAddress}`
					: `https://polygonscan.com/address/${contractAddress}`
			},
			/**
			 * Detects if user has reached the bottom of the window.
			 * @param {Number} contentOffsetBottom - We use this to load the content before the user notice it
			 * @returns {Boolean}
			 */
			hasReachedEndOfTheWindow(contentOffsetBottom = 120) {
				return window.innerHeight + window.pageYOffset >= document.body.offsetHeight - contentOffsetBottom
			},
			formatDate(date, format = 'MMM DD, YYYY, HH:mm:ss A') {
				return this.$dayjs(date).format(format)
			},
			formatDateWithoutHours(date, format = 'MMM DD, YYYY') {
				return this.$dayjs(date).format(format)
			},
			truncateAddress(address) {
				return address.slice(0, 5) + '...' + address.slice(-5)
			},
			sendGTagEvent(eventName) {
				if (!this.$gtag) return

				this.$gtag.event(eventName, { method: 'Google' })
			},
			filterAlphanumerics(string) {
				if (typeof string == 'string') {
					return string.replace(/[^a-zA-Z0-9 ]/g, '')
				}
			},
			getNFTImageUrl(image) {
				if (image && image.includes('ipfs')) {
					return image.replace('ipfs://', 'https://ipfs.io/ipfs/')
				}

				return image
			},
		},

		mounted() {
			if (window.ethereum) {
				this.web3 = this.$web3
			}
		},
	})
}
