import { render, staticRenderFns } from "./error.vue?vue&type=template&id=fac924e2&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=fac924e2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac924e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GameHeader: require('/usr/src/app/components/GameHeader.vue').default,Footer: require('/usr/src/app/components/Footer.vue').default})
