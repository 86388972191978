import Web3 from 'web3'

export default function (ctx, inject) {
	const runtimeConfig = (ctx.$config && ctx.$config.web3) || {}
	const provider = runtimeConfig.provider
	const currentNetwork = ctx.$config.isTestNet ? '80002' : '137'
	const currentRpc = ctx.$config.isTestNet
		? 'https://polygon-polygonAmoy.blockpi.network/v1/rpc/public'
		: 'https://polygon-rpc.com/'

	const web3 = new Web3(
		window.ethereum && window.ethereum.networkVersion === currentNetwork ? window.ethereum : currentRpc || provider
	)

	web3.eth.handleRevert = true
	ctx.$web3 = web3
	inject('web3', web3)

	// With the client-side configuration
	ctx.$Web3 = Web3
	inject('Web3', Web3)
}
