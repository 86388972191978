export default async function ({ route, $axios, store }) {
	if (!process.server || !route.name) {
		return
	}

	try {
		const currentUser = await $axios.get('/api/auth/me').then(res => res.data)
		await Promise.all([store.dispatch('auth/setUser', currentUser)])
	} catch (err) {
		// user unAuthenticated
	}
}
